.pageHeader {
  margin-bottom: 3em;
}

.title {
  margin-bottom: 1em;
}

.orderButtonHolder {
  display: flex;
}
.orderTable tbody tr td img {
  object-fit: cover;
  height: 70px;
  padding-right: 0.5rem;

}
.add {
  padding: 0.4em 0.5em;
  display: flex;
  color: #551a8b;
  min-width: 40px;
  align-items: center;
  justify-content: center;
  border: 3px solid #551a8b;
  font-size: 20px;
  position: relative;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.add:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  color: #eee9f3;
  border: 3px solid #441075;
  background-color: #551a8b;
  cursor: pointer;
}

.orderButtonHolder .filterItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 0 30px;
  font-size: 1rem;
  font-weight: 500;
  align-items: flex-start;
}

.dbHolder {
  display: flex;
  justify-content: flex-end;
}

.orderTable {
  width: 100%;
  min-width: 800px;
}

.orderTable thead,
.orderTable tr {
  border-bottom: 1px solid #dcd7cf;
}

.orderHeading tr,
.orderRow {
  display: grid;
  align-items: center;
  grid-template-columns: 0.7fr 2fr 1fr 1fr 1fr 0.8fr 0.8fr;
}

.orderHeading th,
.orderRow td {
  display: flex;
  padding: 16px 0;
}

.orderRow img {
  width: clamp(16.5vw, 5vw, 5vw);
  max-width: 150px;
  height: auto;
  border-radius: 5px;
}

.orderRow td p {
  font-size: clamp(0.9rem, 100%, 1.2rem);
  font-weight: 400;
  color: #231f20;
  padding: 0 20px;
}

.btn {
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid rgba(0, 0, 255, 0.11);
  border-radius: 0.2em;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1;
  padding: 1em 1.5em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: #1b1b1b;
  box-shadow: 0 0 40px 40px rgba(85, 26, 129, 0.075) inset, 0 0 0 0 rgba(85, 26, 129, 0.158);
  transition: all 150ms ease-in-out;
}
.btn:hover, .btn:focus {
    color: black;
    outline: 0;
    box-shadow: 0 0 10px 0 rgba(61, 39, 139, 0.205) inset, 0 0 10px 4px rgba(85, 26, 129, 0.288);
}

@media (min-width: 767px) {
  .orderButtonHolder {
    justify-content: space-between;
  }
  .orderButtonHolder .filterItem {
    flex-direction: row;
  }
}
