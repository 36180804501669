/* AddNewProject.module.css */
.container {
   
}
.container h2 {
    text-align: center;
    margin-bottom: 2vw;
}
.formContainer {
    width: 95%;
    margin: auto;
    padding: 1vw 2vw;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f8f8f8;
  }
  .fieldContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1vw;
  }
  .lowerCase {
    text-transform: lowercase !important;
  }

  .slugBtn {
    max-width: fit-content;
    padding: 0.5rem 0.3rem ;
    background-color: rgba(77, 77, 77, 0.137);
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 0.6rem;
  }

  .slugBtn:hover { 
    background-color: rgba(170, 170, 170, 0.137);
    ;
  }

  .formSection {
    justify-content: center;
    align-items: end;
    display: flex;
    gap: 1.3vw;
    margin-bottom: 15px;
    width: 100%;
  }
  .formSection *{
    width: 100%;
    text-transform: capitalize;
  }

  .formSection label .textArea {
    height: clamp(3rem, 6.005vw + 1.446rem, 6.25rem);
  }
  .formSection label span {
    text-transform: lowercase;
    color: rgb(179, 179, 179);
  }

  .formSection .mapSection {
    padding: 2px 7px;
    display: flex;
    background-color: rgba(241, 238, 238, 0.575);
  }
  
  .formSection .feature, .amenities {
    height: 6vw;
    min-height: 4rem;
  }

  .inputField {
    /* margin-top: 1rem; */
    border: 2px solid rgba(128, 128, 128, 0.2);
    width: 100%;
    padding: 8px;
    margin-top: 12px;       
    margin-bottom: 10px;
    box-sizing: border-box;
    transition: border-color 0.4s ease;
  }
  .inputField:focus {
    border-color: rgba(58, 15, 15, 0.199);
    outline: none;
  }

  .dropdown {
    border: 2px solid rgba(128, 128, 128, 0.2);
    /* background-color: rgb(241, 238, 238); */
    font-size: clamp(0.75rem, 0.346vw + 0.66rem, 0.938rem);
    width: 100%;
    padding: 9px;
    margin-top: 12px;       
    margin-bottom: 10px;
    box-sizing: border-box;
    transition: border-color 0.4s ease;
  }
.dropdown option:nth-child(1) {
  color: rgb(172, 164, 164);
}

  .dropdown:focus {
    border-color: rgba(58, 15, 15, 0.3);
    outline: none;
  }
  
  .fileInput {
    border-radius: 0.1em;
    padding-left: 2vw;
    padding-top: 9.5px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgb(255, 255, 255);
    height: 40px;
    width: 100%;
    margin-top: 13px;
    margin-bottom: 10px;
  }


  .checkbox {
    margin-top: 11px;
    margin-bottom: 10px;
    height: 30px;
    border-radius: 30px;
    
  }
  
  .submitButton {
    background-color: #2f2497;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submitButton:hover {
    background-color: #4e45a0;
  }
  
  .singleImgSctn {
    display: flex;
    flex-direction: row;
    /* background-color: red; */
    /* justify-content: space-between; */
    /* background-color: red; */
    /* border: 2px solid rgba(128, 128, 128, 0.342); */
    /* box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12); */
    padding: 0rem 0.5rem;
    max-height: 500px;
    overflow-y: scroll;
  }

  .previewImage {
    padding-right: 3px;
    height: 150px;
    width: auto;
    border-radius: 5px;
  }


  
  .oldPreviewBox, .previewGalleryImages {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
  }
.notes {
  color: rgba(105, 105, 105, 0.76);
}
  .multiPreviewImage {
    object-fit: cover;
    height: auto;
    max-width: 22rem;
  }

  .imgBox {
    display: flex;
    position: relative;
    background-color: red;
    padding: 1rem;
  }

  .imgBox span {
    right: 0;
    cursor: pointer;
    color: white;
    padding: 10px 5px;
    align-items: center;
    position: absolute;
    max-width: 5.5rem;
    background-color: rgba(4, 4, 8, 0.381);
  }
  .imgBox span:hover {
    background-color: #0c430ebe;
  } 

  .galleryImgSection{
    margin-bottom: 5vw;
  }