/* AddNewProject.module.css */
.container {
   
}
.container h2 {
    text-align: center;
    margin-bottom: 2vw;
}
.formContainer {
    width: 95%;
    margin: auto;
    padding: 1vw 2vw;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f8f8f8;
  }
  .fieldContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1vw;
  }
  .formSection {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.3vw;
    margin-bottom: 15px;
    width: 100%;
  }
  .formSection label {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 5rem;
  }
  .ame {
    background-color: #F4F2F2;
    /* border-top: 2px dotted gray; */
    /* border-left: 2px dotted gray; */
    border-color: rgba(128, 128, 128, 0.644);
    padding: 0.5rem;
  }
  .fea {
    background-color: #F4F2F2;
    /* border-top: 2px dotted gray; */
    /* border-left: 2px dotted gray; */
    border-color: rgba(128, 128, 128, 0.644);
    padding: 0.5rem;
  }
  .formSection *{
    width: 100%;
    text-transform: capitalize;
  }
  .formSection label span {
    text-transform: lowercase;
    color: rgb(179, 179, 179);
  }

  .formSection .mapSection {
    padding: 2px 7px;
    display: flex;
    background-color: rgba(241, 238, 238, 0.575);
  }
  
  .formSection .feature, .amenities {
    height: 6vw;
    min-height: 4rem;
  }

  .inputField {
    /* margin-top: 1rem; */
    border: 2px solid rgba(128, 128, 128, 0.2);
    width: 100%;
    padding: 8px;
    margin-top: 12px;       
    margin-bottom: 10px;
    box-sizing: border-box;
    transition: border-color 0.4s ease;
  }
  .inputField:focus {
    border-color: rgba(58, 15, 15, 0.7);
    outline: none;
  }

  .dropdown {
    border: 2px solid rgba(128, 128, 128, 0.2);
    /* background-color: rgb(241, 238, 238); */
    font-size: clamp(0.75rem, 0.346vw + 0.66rem, 0.938rem);
    width: 100%;
    padding: 9px;
    margin-top: 12px;       
    margin-bottom: 10px;
    box-sizing: border-box;
    transition: border-color 0.4s ease;
  }
.dropdown option:nth-child(1) {
  color: rgb(172, 164, 164);
}

  .dropdown:focus {
    border-color: rgba(58, 15, 15, 0.3);
    outline: none;
  }
  
  .fileInput {
    border-radius: 0.1em;
    padding-left: 2vw;
    padding-top: 9.5px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgb(255, 255, 255);
    height: 40px;
    width: 100%;
    margin-top: 14px;
    margin-bottom: 2vw;
    border: 1px dotted gray;
    padding-top: 2vw;
    padding-bottom: 3.2vw;
  }
  .previewImages {
  margin-top: 1.8vw;
  }
  .previewGalleryImages {
    margin-bottom: 2vw;

  }


  .checkbox {
    margin-top: 11px;
    margin-bottom: 10px;
    height: 30px;
    border-radius: 30px;
    
  }
  
  .submitButton {
    background-color: #2f2497;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submitButton:hover {
    background-color: #4e45a0;
  }
  
  .singleImgSctn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 0.3rem;
    max-height: 200px;
    /* overflow-y: scroll; */
  }
  .multiImgSctn {
    display: flex;
    flex-direction: row;
    padding: 0.5rem 0.3rem;
    max-height: 260px;
    overflow-y: scroll;
  }

  .previewImage {
    padding-right: 3px;
    margin-top: 0.85rem;
    height: 105px;
    width: auto;
    padding: 0.2rem;
    border: 1px dotted gray;
  }
  .multiPreviewImage {
    padding-right: 3px;
    height: 100px;
    width: auto;
  }

  .suggest{
    opacity: 0.7;
     color: rgb(39, 39, 39);
     
  }

  .optionsContainer {
    height: 125px; /* Adjust height as needed */
    overflow-y: auto;
  }
  
  .optionsList {
    padding: 5px;
  }
  
  .optionsList div {
    cursor: pointer;
    padding: 5px;
    background-color: #ffffff; 
  }
  
  .optionsList div:hover {
    /* color: white; */
    background-color: #cccccc52; /* Highlight on hover */
  }

.error {
  color: rgb(230, 18, 18);
  padding-left: 0.5rem;
}
.success {
  color: rgb(43, 230, 18);
  padding-left: 0.5rem;
}

  @media (max-width: 950px) {
    .formSection label {
      min-height: 9rem;
    }
  }
  