.modal-wrapper{
  padding:0 2rem 2rem 2rem;
  font-size: 16px;
  font-family: Gotham;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.444);
  padding-bottom: 1.3rem;
}
.header-left {
  flex: 1;
}
.header-left p {
  margin-top: 0.5rem;
  font-weight: 500;
}
.header-left p span {
  border-right: 1px solid rgba(128, 128, 128, 0.562);
  padding-right: 1rem;
  margin-right: 0.8rem;
}
.header-left h2 span {
  font-weight: 200;
}
.header-right {
  display: flex;
  gap: 10px;
}
.btn-cancel {
  background-color: #fef5f7;
  color: #fc536c;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}
.btn-status {
  margin-bottom: 0.5rem;
  background-color: #fff5f7;
  color: #f5bb75;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}
.middle-divider {
  border: 1px solid #DCD7CF;
  margin-left: 2rem;
  margin-right: 2rem;
}
.btn-cancel:hover {
  background-color: #ebd8dc;
}
.btn-accept {
  background-color: #32a85a;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}
.btn-accept:hover {
  background-color: #1d833f;
}
.body-left {
  padding-right: 4rem;
}
.form-group{
  padding-bottom: 1rem;
}
.form-group label {
  color: #231f20;
  font-family: Gotham;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  
}
.form-group input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #dcd7cf;
  padding-top: 1rem;
  font-family: Gotham;
  font-weight: 400;
  font-size: 16px;
}

.form-group input:focus {
  outline: none;
  border-bottom: 1px solid #dcd7cf;
  background-color: #fff;
}

.body-right {
  padding-left: 2rem;
  padding-right: 5rem;
}

.body-right .outlet {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.body-right .outlet input{
  cursor: pointer;
  accent-color: #32A85A;
  background-color: #32A85A;
}

.body-right .outlet input label{
  font-weight: 400;
}

.body-right .role-part{
  padding-top: 2rem;
}

.body-right .role-part select {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  font-size: 16px;
  cursor: pointer;
}

.table-container tbody td {
}

.borderRemoted td {
  /* background-color: rgba(255, 0, 0, 0.07); */
  border-top: 1px solid rgba(128, 128, 128, 0);
}
