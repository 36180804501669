.login_page{
    background-color: #fff;
    /* display: flex;
    flex-direction: row;
    justify-content: flex-end; */
    display: grid;
    grid-template-columns: 2fr 1fr;
    /* width: clamp(340px, 90%, 1280px); */
}
/* Login Form */
.container{
        /* width: clamp(340px, 90%, 1280px); */
}
.lefty{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    padding-right: clamp(60px, 90%, 100px);
    padding-top: 40px;
}
.lefty .logo{
    width: clamp(60px, 90%, 171.85px);
}
.lefty h2{
    font-family: 'Gotham';
    font-size: clamp(1rem, -0.875rem + 8.333vw, 2.3rem);
    font-weight: 400;
    line-height: 1.5rem;
    padding-top: 22vh;
    color: #231F20;
    padding-bottom: 1rem;
}
.lefty p{
    font-family: 'Gotham';
    font-size: clamp(0.9rem, -0.875rem + 8.333vw, 1.1rem);
    font-weight: 500;
    color: #AB946D;
}
.lefty p::after{
    content: '';
    padding-top: 20px;
    border-bottom: 1px solid #AB946D;
    width: 25vw;
    display: block;
}
.lefty form{
    flex-direction: column;
}
.lefty input{
    width: clamp(15vw, 98% ,23vw);
    background: #F7F7F7;
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 18px 20px;
    margin: 16px 0;
    color: #231F20;
    font-family: 'Gotham';
    font-size: clamp( 0.9rem, -0.875rem + 8.333vw, 1.1rem );
    font-weight: 500;
}
.lefty input::placeholder{
    color: #231F2066;
    font-size: 1.4rem;
    line-height: 1.5em;
}
.lefty form label{
    font-family: 'Gotham';
    font-size: clamp( 0.9rem, -0.875rem + 8.333vw, 1.1rem );
    line-height: 1.4rem;
    font-weight: 500;
    color: #231F20;
}
.lefty button{
    width: 10vw;
    /* margin-left: auto; */
    background: #32A85A;
    color: #FFFFFF;
    border-radius: 5px;
    padding: 15px 35px;
    border: 1px solid transparent;
    font-family: 'Gotham';
    font-size: clamp( 0.9rem, -0.875rem + 8.333vw, 1.1rem );
    font-weight: 500;
}


/* Right column image */
.righty{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.righty img{
    width: auto;
    height: 100vh;
}

.btnSection {
    display: flex !important;
    flex-direction: column;
    align-items: start;
    justify-content: left;
}

.btnSection span{ 
    padding-bottom: 0.5rem;
    color: rgba(255, 0, 0, 0.8);
}
