.pageHeader {
    margin-bottom: 2em;
  }
  
  .pageHeader .title {
    margin-bottom: 1em;
  }
  .order-button-holder {
      display: flex;
    }
 
    
  .order-table {
      width: 100%;
      min-width: 800px;
    }
    .order-table thead,
    .order-table tr {
      border-bottom: 1px solid #dcd7cf;
    }
    .order-heading tr,
    .order-row {
      display: grid;
      /* grid-template-columns: repeat(7, 1fr); */
      grid-template-columns: 0.1fr 1fr 1fr 0.5fr 0.5fr;
      
    }
    .order-heading  th,
    .order-row td {
      display: flex;
      align-items: center;
      padding: 16px 0;
    }
    .order-row img {
      width: 120px;
      height: 80px;
      border-radius: 3px;
      object-fit: cover;
    }
    .order-row td p {
      font-size: clamp(0.9rem, 100%, 1.2rem);
      font-weight: 400;
      color: #231f20;
      padding: 0 20px;
    }


  
    @media (min-width: 767px) {
      .order-button-holder {
        justify-content: space-between;
      }
      .order-button-holder .filterItem {
        flex-direction: row;
      }
    }