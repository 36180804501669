.sidebar {
  position: relative;
  background: #ece9ee;
  padding: 40px 30px 40px 0;
  width: 100%;
  min-height: 100%;
  /* max-width: 300px; */
  z-index: 100;
  transition: all 0.3s ease-in-out;
  position: relative;
  box-shadow: -4px 7px 14px -2px rgba(145, 145, 145, 0.75);
}

.sidebar-items{
  margin-top: 2vw;
}
 .main-logo {
  /* background-color: red; */
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
  /* background-color: red; */
}
 .main-logo img{
  object-fit: cover;
  height: clamp(2.813rem, 4.144vw + 1.74rem, 5.625rem);
}
.sidebar-items .open {
  background-color: #dcdae469;
}

.main-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 14px;
}

.logout {
  margin-left: 1rem;
  cursor: pointer;
  padding-bottom: 1rem;
  position: absolute;
  top: 97%;
  display: flex;
  align-items: center;
  font-size: clamp(1.2rem, 1.5vw, 1.5rem);
  color: #4a57c7;
  font-weight: 300;
  line-height: 1.2em;
  text-decoration: none;
  padding-left: 16px;
}

.logout:hover {
  background: #ffffff0e;
  border-left: 5px solid #5168ec;
  border-radius: 0 40px 40px 0;
}


.sidebar.active {
  left: 0;
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 5px 0px 7px 20px;
}

.sidebar-item .icon {
font-size: 2rem;
}

.sidebar-items .titles {
  width: 100%;
  margin-bottom: 5px;
  padding: 10px 10px 10px 25px;
  cursor: pointer;
  font-size: 20px ; 
  transition: background-color 0.3s;
}

.sidebar-items .sidebar-item:hover {
  background-color: #d9dee4d3;
}

.sidebar-items .sidebar-item.active {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  background-color: #a6c4d848;
}

.logout-title {
  cursor: pointer;
  padding: 10px;
  font-size: 20px ; 
  font-weight: bold; 
  transition: background-color 0.3s;
}

.logout-title:hover {
  background-color: #a6c4d8d2;
}

.third {
  border-color: blue;
  color: #fff;
  box-shadow: 0 0 40px 40px blue inset, 0 0 0 0 blue;
  transition: all 150ms ease-in-out;
  
  &:hover {
    box-shadow: 0 0 10px 0 blue inset, 0 0 10px 4px blue;
  }
}

@media (max-width: 1100px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    margin-left: -100%;
    max-width: 300px;
    height: 139vh;
  }
  .sidebar.active {
    margin-left: 0;
  }
  .sidebar .close {
    width: auto;
    position: absolute;
    top: 20px;
    right: 20px;
    display: block;
  }
}
