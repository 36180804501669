.admin {
  /* padding: 1rem; */
  /* background-color: #f4f4f4; */
  height: 100%;
}

table {
  border-collapse: collapse;
}
tr {
  border-bottom: 0.5px solid #dcd7cf;
}
.admin-header {
  display: flex;
  justify-content: space-between;
}

.admin .admin-header .page-title {
  font-size: clamp(1rem, -0.875rem + 8.333vw, 1.8rem);
  font-weight: 600;
  line-height: 1.5em;
}
.admin .admin-header .number-of-result {
  color: gray;
}

.admin-table-wrapper {
  overflow-x: auto;
  padding-top: 2rem;
}
.admin-table-wrapper .admin-table {
  min-width: 800px;
  width: 100%;
}

.admin-table-wrapper .admin-table .table-row {
}
.admin-table-wrapper .admin-table .table-row > th,
.admin-table-wrapper .admin-table .table-row > td {
  text-align: start;
  padding: 1rem 0 0.825rem;
  font-size: clamp(0.8rem, -0.875rem + 8.333vw, 1rem);
}

.add-button {
  background: #ff7f50;
  font-size: clamp(0.75rem, 1.5vw, 1rem);
  padding: clamp(0.365rem, 1.5vw, 0.865rem) clamp(0.865rem, 1.5vw, 1.5rem);
  border-radius: 5px;
  outline: none;
  border: none;
  white-space: nowrap;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
.add-button:hover {
  background: #ff6933;
}
