textarea {
  min-height: 7rem;
  height: 31vh;
  padding: 3px;
  width: 100%;
  font-size: clamp(0.813rem, 0.346vw + 0.723rem, 1rem);
}


.container {
  width: 80%;
  margin: 0 auto;
}
.slider {
  padding: 1.5rem 0rem;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.imageContainer {
  position: relative;
  overflow: hidden;
}

.imageContainer img {
  width: 100%;
  height: auto;
  display: block;
}

.deleteButton,
.changeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  color: red;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.addButton {
  margin-top: 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.offerProject {
  padding: 1.5rem 0rem;
  border-top: 1px solid rgba(128, 128, 128, 0.479);
  border-bottom: 1px solid rgba(128, 128, 128, 0.514);

}