.container {
  width: 80%;
  margin: 0 auto;
}

h3 {
  /* margin-top: 0rem; */
}
.previewImage {
  max-height: 13rem;
}
.formContainer {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05), inset 0px 0px 0px 1px rgba(209, 213, 219,1);
  padding: 1vw;
}

/*******Green Building concept Section*****/
.section {
  /* background-color: #00a5050c; */
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05), inset 0px 0px 0px 1px rgba(209, 213, 219,1);
  padding: 1vw;
  margin-bottom: 3vw;
}

.formSection {
  /* padding: 1vw;
  margin-bottom: 3vw;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05), inset 0px 0px 0px 1px rgba(209, 213, 219,1); */
}

.addGreenBtn {
  border: 1px solid rgba(0, 128, 0, 0.185);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px 1px rgba(9, 30, 66, 0.13);
  width: fit-content;
  padding: 4px 15px;
  margin-bottom: 2vw;
}

.addGreenBtn:hover {
  background-color: rgba(0, 128, 0, 0.384);
}

.textarea {
  width: 100%;
  margin-top: 3px;
  padding: 3px;
  min-height: 7vw;
  font-size: clamp(0.75rem, 0.462vw + 0.63rem, 1rem);
}

.tableRow {
  /* width: 100%; */
  display: grid;
  border-bottom: none;
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0), 0px 0px 0px 1px rgba(9, 30, 66, 0.13);
}

.tableRow td {
  padding: 4px 5px;
}

.Btn {
  color: rgb(19, 18, 18);
  background-color: rgba(63, 63, 63, 0) !important;
  padding: 10px 13px !important;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
}

.Btn:hover {
  background-color: rgba(92, 92, 92, 0.473) !important;

}

.deleteButton,
.changeButton {
  position: absolute;
  top: 5px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  color: red;
  border: none;
  padding: 2px;
  cursor: pointer;
}

/******** MaterialsSection Specification SEction  *********/
.tableBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  /* background-color: red; */
}