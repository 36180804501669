.container {
    width: 80%;
    margin: 0 auto;
  }
  .container h2 {
    margin-bottom: 2vw;
  }
  .slider {
    padding: 1.5rem 0rem;
  }

  .formSection .feature, .amenities {
    height: 6vw;
    min-height: 4rem;
  }


  .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .textArea {
    margin-top: 0.5rem;
    height: 3rem !important;
    border: 1px solid rgba(128, 128, 128, 0.356);
    
  }
  .imageContainer {
    position: relative;
    overflow: hidden;
  }
  
  .imageContainer img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .deleteButton,
  .changeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    color: red;
    border: none;
    padding: 5px;
    cursor: pointer;
  }
  
  .addButton {
    margin-top: 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .offerProject {
    padding: 1.5rem 0rem;
    border-top: 1px solid rgba(128, 128, 128, 0.479);
    border-bottom: 1px solid rgba(128, 128, 128, 0.514);

  }

  .oldPreviewBox, .previewGalleryImages {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
  }
.notes {
  color: rgba(105, 105, 105, 0.76);
}
  .multiPreviewImage {
    object-fit: cover;
    height: auto;
    max-width: 22rem;
  }

  .imgBox {
    display: flex;
    position: relative;
  }

  .imgBox span {
    right: 0;
    cursor: pointer;
    color: white;
    padding: 10px 5px;
    align-items: center;
    position: absolute;
    max-width: 5.5rem;
    background-color: rgba(4, 4, 8, 0.381);
  }
  .imgBox span:hover {
    background-color: #0c430ebe;
  } 

