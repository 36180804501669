/* contactInfo css */
.container {
  width: 80%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

h2 {
  text-align: center;
}

form {
  max-width: 1000px;
  margin: 0 auto;
}

h3 {
  margin-top: clamp(0.938rem, 1.963vw + 0.43rem, 2rem);
  margin-bottom: 10px;
}

label {
  display: block;
  margin-top: 10px;
  font-weight: normal;
}

input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 15px;
}

.container .twoDiv .labelContainer input {
  min-width: 100%;
}

button {
  background-color: #4e45a0;
  color: rgb(236, 236, 236);
  padding: 15px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #28772c;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.twoDiv {
  display: flex;
  justify-content: space-between;
  gap: 2vw;
  
}
.twoDiv .labelContainer {
  /* background-color: red; */
  width: 100%;
}
