/* Variables */
:root {
  --black: #231f20;
}

.test p {
  color: rgb(84, 204, 104);
  font-size: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .container{
  display: flex;
  justify-content: center;
  align-items: center;
} */

@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

body {
  font-family: 'Arial', sans-serif; 
  color: rgb(53, 53, 53); /* Text color */
  font-weight: 300; /* Normal font weight */
}

.home,
.reports,
.products,
.team,
.reports {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.page-container {
  display: grid;
  grid-template-columns: 1fr 5fr;
  height: 100vh;
  font-family: "Gotham";
}

.topbar {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.title {
  color: rgb(51, 51, 53);
}
.dash {
  padding: 30px 60px 30px 30px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #e2e2e2;
  box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 1.2em;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}
.askModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #e2e2e2;
  box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 1.2em;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.close-btn {
  align-self: end;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 2px solid var(--black);
  color: var(--black);
  position: relative;
  cursor: pointer;
  margin-bottom: 1rem;
}
.ask-alert-bottons {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin-top: clamp(0.938rem, 2.545vw + 0.279rem, 2.188rem);
  margin-bottom: 0.1rem;


}
.ask-close-btn {
  padding: 0.4rem 0.8rem;
  align-self: end;
  color: var(--black);
  border: 1px solid rgba(128, 128, 128, 0.459);
  position: relative;
  cursor: pointer;
  transition: color 0.3s ease;
  background-color: rgba(209, 209, 209, 0.849);
  
}
.ask-close-btn:hover {
  background-color: rgba(0, 0, 0, 0.781);
  color: white;
}

.close-btn::before {
  content: " ";
  position: absolute;
  display: block;
  background-color: var(--black);
  width: 2px;
  left: 12px;
  top: 5px;
  bottom: 5px;
  transform: rotate(45deg);
}

.close-btn::after {
  content: " ";
  position: absolute;
  display: block;
  background-color: var(--black);
  height: 2px;
  top: 12px;
  left: 5px;
  right: 5px;
  transform: rotate(45deg);
}

.close-btn:hover {
  background-color: var(--black);
  border-color: var(--black);
}

.close-btn:hover::before,
.close-btn:hover::after {
  background-color: white;
}

.addLocationBtn {
  padding: 0.4em 0.5em;
  display: flex;
  color: #551a8b;
  min-width: 40px;
  align-items: center;
  justify-content: center;
  border: 3px solid #551a8b;
  font-size: 20px;
  position: relative;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.addLocationBtn:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  color: #eee9f3;
  border: 3px solid #441075;
  background-color: #551a8b;
  cursor: pointer;
}

.viewDeatilsBtn {
  padding: 0.4em 0.5em;
  display: flex;
  color: #551a8b;
  min-width: 40px;
  align-items: center;
  justify-content: center;
  border: 1px solid #551a8b;
  font-size: 20px;
  position: relative;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.btn {
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  max-width: 12rem;
  border: 2px solid rgba(0, 0, 255, 0.11);
  border-radius: 0.2em;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1;
  padding: 1em 1.5em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: #1b1b1b;
  box-shadow: 0 0 40px 40px rgba(85, 26, 129, 0.075) inset, 0 0 0 0 rgba(85, 26, 129, 0.158);
  transition: all 150ms ease-in-out;
}
.btn:hover, .btn:focus {
    color: black;
    outline: 0;
    box-shadow: 0 0 10px 0 rgba(61, 39, 139, 0.205) inset, 0 0 10px 4px rgba(85, 26, 129, 0.288);
}

/* // custom scroll bar; */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(84, 26, 139, 0.803);
  background: linear-gradient(
    98deg,
    rgba(84, 26, 139, 0.882) 0%,
    rgba(84, 26, 139, 0.862) 48%,
    rgba(84, 26, 139, 0.848)100%
  );
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(41, 61, 90);
  background: linear-gradient(
    98deg,
    rgb(84, 26, 139, 0.882) 0%,
    rgb(84, 26, 139, 0.882) 48%,
    rgb(84, 26, 139, 0.882) 100%
  );
  cursor: grab;
}
