.views {
    display: flex;
    flex-direction: column;
}

.views p {
    padding-bottom: 0.3rem;
}

.btn {
    margin-top: 0.7rem;
}