.OTB-root-container {
  background-color: #ece9eebb;
  box-shadow: -4px 7px 14px -2px rgba(145, 145, 145, 0.35);
  margin: 0;
  padding: 0;
}
.OTB-root-container *{ 
  
}
.OTB-container {
  font-family: "Gotham";
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 200;
  /* display: grid;
  grid-template-columns: 4fr 4fr 1fr 2fr; */
  width: 100%;
  padding: 30px 60px 30px 30px;
  border-bottom: 1px solid #dcd7cf;
}
.OTB-container .close {
}
.OTB-root-container .left-sidebar-toggle-btn {
  width: auto;
  display: none;
}
.OTB-root-container .left-sidebar-toggle-btn button,
.OTB-root-container .right-sidebar-toggle-btn button {
  background-color: rgba(145, 145, 145, 0.1);
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.2rem 0.5rem;
}
.OTB-root-container .left-sidebar-toggle-btn button img {
  rotate: -180deg;
}
.OTB-root-container .right-sidebar-toggle-btn {
  width: auto;
  display: none;
}
.OTB-container.active {
  margin-right: -100%;
}
.OTB-root-container .close {
  display: none;
}
/************ Searchbar ************/
.searchBar {
  display: flex;
  align-items: center;
  border: 1px solid #dcd7cf1a;
  box-shadow: inset 0 3px 5px 0 #200e320d;
  border-radius: 30px;
  justify-content: space-between;
  padding: 0 20px;

  max-width: 250px;
}
.searchBar .searchBox {
  width: 95%;
  border: 1px solid transparent;
  background: none;
  padding: 10px 0;
  border-radius: 30%;
  font-size: clamp(0.9rem, 100%, 1.2rem);
  font-weight: 600;
  color: #231f20;
  letter-spacing: 1.05px;
}
.searchBar .searchBox:focus {
  outline: none !important;
  font-size: clamp(0.9rem, 100%, 1.2rem);
  font-weight: 600;
}

/************ Date & Time ************/
.dateTime {
  display: flex;
  align-items: center;
}
.currentDate,
.currentTime {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.currentDate {
  padding-right: 30px;
}
.currentDate h2,
.currentTime h2 {
  font-size: clamp(1.25rem, 100%, 1.3rem);
  font-weight: 400;
  padding: 0 0 4px 5px;
}
.currentDate .icony,
.currentTime .icony {
  width: 20px;
  height: 20px;
}
.nav-right {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.notifications {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.notifications .icony {
  padding: 10px 13px;
  background: #200e320d;
  border-radius: 50%;
}
.icony:after {
  content: "\A";
  width: 7px;
  height: 7px;
  border: 1px solid #fff;
  border-radius: 50%;
  background: #6c7081;
  display: inline-block;
  position: absolute;
  margin: -22px 0 0 8px;
}

.profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}
.profile .profileImage {
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #2b6116;
  border-radius: 10%;
}
.profile .profileImage img{
  width: 45px;
  max-height: 45px;
}
.profile .profileInfo {
  padding: 0 0 0 10px;
}
.profile .profileInfo h3 {
  font-size: clamp(1.1rem, 100%, 1.2rem);
  color: #231f20;
  font-weight: 400;
  line-height: 1.5em;
  white-space: nowrap;
}
.profile .profileInfo h4 {
  font-size: clamp(1rem, 100%, 1.1rem);
  font-weight: 500;
  color: #716dab;
}

@media (max-width: 1100px) {
  .OTB-root-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
  }
  .OTB-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    max-width: 300px;
    height: 139vh;
    margin-right: -100%;
    background-color: rgb(199, 198, 209);
    transition: all 0.5s ease-in-out;
    box-shadow: -4px 7px 14px -2px rgba(145, 145, 145, 0.75);
  }
  .OTB-container.active {
    margin-right: 0%;
  }
  .nav-right {
    flex-direction: column;
    align-items: center;
  }
  .dateTime {
    flex-direction: column;
    align-items: flex-start;
  }
  .dateTime .currentDate,
  .dateTime .currentTime {
    padding-right: 0;
    padding-top: 1rem;
  }
  /* .notifications {
    place-self: flex-start;
  } */
  .profile {
    margin: 1rem 0 0;
  }
  .OTB-container .close {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .OTB-container .close div {
    height: 30px;
    width: 30px;
    position: relative;
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-color: rgba(145, 145, 145, 0.1);
    border-radius: 5px;
  }
  .OTB-container .close div span:nth-child(1),
  .OTB-container .close div span:nth-child(2) {
    position: absolute;
    left: 0;
    top: 50%;
    height: 1px;
    width: 100%;
    background-color: #57363e;
    transform: translateY(-50%);
  }
  .OTB-container .close div span:nth-child(1) {
    transform: rotate(45deg);
    }
  .OTB-container .close div span:nth-child(2) {
  transform: rotate(-45deg);
  }
  .OTB-root-container .left-sidebar-toggle-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .OTB-root-container .right-sidebar-toggle-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .OTB-container > div {
    padding: 0.5rem;
  }
}
