/* --------------------------layout dashboard start-------------------------- */
.layout {
  display: flex;
}

.layout .layout-sidebar {
  flex: 2;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.layout .layout-main-container {
  flex: 6;
}
.layout .layout-main-container .layout-dashboard-contents {
  padding: 1rem;
  height: calc(100vh - 120px);
  /* max-height: calc(100vh 150px); */
  overflow-y: auto;
}

@media (max-width: 1100px) {
  .layout .layout-sidebar {
    flex: 0;
  }
  .layout .layout-main-container {
    flex: 6;
    overflow-x: hidden;
  }
  .layout .layout-main-container .layout-dashboard-contents {
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    /* max-height: auto; */
  }
}

/* --------------------------layout dashboard end -------------------------- */
